import React, { useState, useEffect} from "react";
import { Link } from "react-router-dom";

import { useStoreon } from "storeon/react";
import { useQuery, useMutation } from "@apollo/client";
import { message, Modal, Input, Table, Row, Col, Typography } from "antd";

import { isAdmin, MESSAGE_NO_ACCESS } from "../../auth/authHelper";

import gql from "graphql-tag";
import moment from "moment";

const { TextArea } = Input;
const { Text } = Typography;

const BotEmailTemplate = (props) => {
  const { auth, ui, dispatch } = useStoreon("auth", "ui", "selection");
  const { component } = ui.visibleForm;
  const [ isModified, setIsModified ] = useState(false);
  const [ areaValue, setAreaValue ] = useState();
  const [ areaError, setAreaError ] = useState();

  const [ update_crt_config, { data: d_data, loading: d_loading, error: d_error } ] = useMutation(UPDATE_CRT_MAIL_CONTENT, {
    refetchQueries: ["MAIL_CONTENT"],
    //onError: (e) => processError(e) 
  });

  useEffect(() => {
     // alert(JSON.stringify(d_data));
 
     if (d_data?.update_crt_config?.affected_rows == 1) {
       message.success('Template updated', 0.5);
       setIsModified(false);
       closeForm();
     }
  }, [d_data]);

/*
  const processError = (e) => {
    console.log("Got errors: ");
    e.graphQLErrors.map( error => {
      console.log(error);
      message.error('Server error, contact your administrator');
    });
  }
*/

  const { loading, error, data } = useQuery(GET_CRT_MAIL_CONTENT, {variables: {},});

  const parseTemplate = (t) => (t ? t.replaceAll('<br/>', "") : t );

  useEffect(() => {

    if (!data?.crt_config[0]) return;

    const template = data?.crt_config[0];
    const parsedValue = parseTemplate(template.par_value);
    setAreaValue(parsedValue);

  }, [data]);

  if (!isAdmin()) return <>You have not permissions</>;

  const closeForm = () => {
    dispatch("hideForm");
  };

  const handleSubmit = () => {
    console.log("handleSubmit: ");
    // e.preventDefault();
    const mod = areaValue.replaceAll(/\n/g, '<br/>\n');
    // alert('update_crt_config: '+ mod);
    update_crt_config ({ variables: { objects: { par_value: mod } } });
  };

  const handleCancel = () => {
    const template = data?.crt_config[0];
    const parsedValue = parseTemplate(template.par_value);

    setAreaValue(parsedValue);
    setIsModified(false); 
    closeForm();
  };


  const onChangeHandler = ({currentTarget: { value: v}}) => {

    // console.log("onChangeHandler: ", v);

    const p1 = (v||'').indexOf('{}');
    const p2 = p1 ? v.indexOf('{}', p1 + 1) : 0;

    setIsModified (true);

    if (p1 !== -1 && p2 !== -1 && p1 != p2) { 
      setAreaError();       
    } else {
      setAreaError('The template must contain two placeholders');
    }

    setAreaValue(v);
  };

  /////////////////////////////////////
  return (
      <Modal
        maskClosable={false}
        width="1000px"
        title="Certificate mail content template"
        visible={component === "crt_mail_content_editor"}
        onOk={handleSubmit}
        okText="Save"
        okButtonProps={{disabled: !isModified || areaError }}
        onCancel={handleCancel}
        cancelButtonProps={{disabled:false}}
      >
      {loading && "Loading..."}
      {error && `Error! ${error.message}`}

      {!loading && !error && <>
        <Text type="secondary"><b>Hint:</b> {data?.crt_config[0]?.comment}</Text>
        <TextArea 
           rows={4} 
           showCount 
           placeholder="Certificate mail content template"  
           style={{
             height: 120,
             resize: 'none',
           }}
           value={areaValue}
           onChange={(v) => { onChangeHandler(v) }}
        />
        {areaError && <Text type="danger">{areaError}{d_error}</Text> }
        {d_loading && <Text type="secondary">Submitting...</Text> }
        {d_error && <Text type="danger">Submission error! {d_error}</Text> }
        </>
      } 
    </Modal>
  );
};

const GET_CRT_MAIL_CONTENT = gql`
query MAIL_CONTENT {
  crt_config (where: {par_name: {_eq: "MAIL_CONTENT"}}) {   
    par_value
    comment
  }
}
`;

const UPDATE_CRT_MAIL_CONTENT = gql`
  mutation UPDATE_MAIL_CONTENT ($objects: crt_config_set_input!) {
    update_crt_config (_set: $objects, where: {par_name: {_eq: "MAIL_CONTENT"}}) {
      affected_rows
    }
  }
`;


 
export default BotEmailTemplate;
